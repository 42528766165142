import React from 'react';
import Image from 'next/image';
import LogoImg from '@/assets/logonew.png';
import { general as generalConf } from '@/config/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faBars,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { cn } from '@/utils';
import { useMobileResponsive } from '@/hooks';

const navItems = [
  { name: `Home`, url: `/` },
  { name: `Reviews`, url: `/reviews` },
  { name: `Meditate`, url: `/meditate` },
  { name: `Blogs`, url: `/blogs` },
  { name: `Affirmations`, url: `/affirmation` },
  { name: `Our Team`, url: `/team` },
  {
    name: `Work with Us`,
    url: generalConf.workWithUsMailToLink,
    showInMobileOnly: true,
    type: `CAREER`,
  },
  { name: `LSM course`, url: `/course?source=Ranveer-Beginners-Ads` },
];

const emailLinks = [
  {
    name: `Business`,
    url: `mailto:hey@level.game?subject=Level%20Business%20Collaboration&body=Hello%2C%0D%0A%0D%0AWe%20would%20like%20to%20collaborate%20with%20Level.%0D%0A%0D%0ADetails%20of%20collaboration%0D%0A1)%0D%0A2)%0D%0A3)`,
  },
  {
    name: `Colleges`,
    url: `mailto:hey@level.game?subject=Level%20Business%20Collaboration&body=Hello%2C%0D%0A%0D%0AWe%20would%20like%20to%20collaborate%20with%20Level.%0D%0A%0D%0ADetails%20of%20collaboration%0D%0A1)%0D%0A2)%0D%0A3)`,
  },
  {
    name: `Schools`,
    url: `mailto:hey@level.game?subject=Level%20Business%20Collaboration&body=Hello%2C%0D%0A%0D%0AWe%20would%20like%20to%20collaborate%20with%20Level.%0D%0A%0D%0ADetails%20of%20collaboration%0D%0A1)%0D%0A2)%0D%0A3)`,
  },
  {
    name: `Corporates`,
    url: `mailto:hey@level.game?subject=Level%20Business%20Collaboration&body=Hello%2C%0D%0A%0D%0AWe%20would%20like%20to%20collaborate%20with%20Level.%0D%0A%0D%0ADetails%20of%20collaboration%0D%0A1)%0D%0A2)%0D%0A3)`,
  },
  {
    name: `Coaches`,
    url: `mailto:hey@level.game?subject=Level%20Business%20Collaboration&body=Hello%2C%0D%0A%0D%0AWe%20would%20like%20to%20collaborate%20with%20Level.%0D%0A%0D%0ADetails%20of%20collaboration%0D%0A1)%0D%0A2)%0D%0A3)`,
  },
];

function FloatingNav() {
  const ulRef = React.useRef<HTMLUListElement>(null);
  const [show, setShow] = React.useState(false);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(() => ulRef.current?.scrollHeight || 0);
  }, []);

  const { windowWidth } = useMobileResponsive();

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <div className="relative px-2 ml-6 md:ml-0">
        <button
          onClick={() => setShow((prev) => !prev)}
          className="md:min-w-[10ch]"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Partner with us &nbsp;
          {show ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              width="1em"
              height="1em"
              style={{ color: `#ffff` }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              width="1em"
              height="1em"
              style={{ color: `#ffff` }}
            />
          )}
        </button>

        <ul
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={ulRef}
          style={{ height: show ? height : 0 }}
          className={cn(
            `absolute left-0 right-0 top-[1.6rem] rounded-b-2xl overflow-hidden transition-all`,
            { 'bg-level-purple z-50': windowWidth > 1300 },
            { 'bg-level-purple': windowWidth < 1300 },
          )}
        >
          {emailLinks.map((link, i) => (
            <li className="p-2" key={i}>
              <a href={link.url}>{link.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export function Nav() {
  const mdViewport = 1300;
  const logoRef = React.useRef<HTMLAnchorElement>(null);

  const [showNav, setShowNav] = React.useState(false);
  const [pathname, setPathname] = React.useState<string>();
  const [logoWidth, setLogoWidth] = React.useState(0);
  const [isAppTraffic, setIsAppTraffic] = React.useState(false);

  const { windowWidth } = useMobileResponsive();

  React.useEffect(() => {
    setPathname(window.location.pathname);

    const logoEl = logoRef.current;
    const visibleNavListener = () => {
      if (window.innerWidth > mdViewport) setShowNav(true);

      if (logoEl) {
        const width = logoEl.getBoundingClientRect().width;
        const winWidth = window.innerWidth;

        setLogoWidth(() => (winWidth > mdViewport ? width : 0));
      }
    };

    visibleNavListener();
    window.addEventListener(`resize`, visibleNavListener);

    // hide nav if the traffic is coming from levelapp
    const source = new URLSearchParams(window.location.search).get(`source`);
    if (source === `levelapp`) setIsAppTraffic(() => true);
    if (window.location.pathname.startsWith(`/user`)) {
      setIsAppTraffic(() => true);
    }

    if (window.location.pathname.startsWith(`/user`)) {
      setIsAppTraffic(() => true);
    }

    if (window.location.pathname.startsWith(`/top-meditators`)) {
      setIsAppTraffic(() => true);
    }

    return () => {
      window.removeEventListener(`resize`, visibleNavListener);
    };
  }, []);

  React.useEffect(() => {
    if (showNav && window.innerWidth < mdViewport) {
      window.document.body.style.position = `fixed`;
    } else {
      window.document.body.style.position = `static`;
    }
  }, [showNav]);

  if (isAppTraffic) return <></>;

  return (
    <div
      className={[
        `mx-auto`,
        `px-4 py-2`,
        // `max-w-[90rem]`,
        ``,
        `flex`,
        `items-center `,
        `bg-[#0F0622]`,
        `text-white`,
        `sticky top-0 w-full`,
        `z-50`,
      ].join(` `)}
    >
      {/* eslint-disable-next-line */}
      <a
        href="/"
        className={cn(`max-w-[10rem] ml-0  sm:ml-4 md:ml-1  xl:ml-[9rem]`)}
        ref={logoRef}
      >
        <Image
          src={LogoImg.src}
          width={LogoImg.width}
          height={LogoImg.height}
          placeholder="blur"
          blurDataURL={LogoImg.blurDataURL}
          alt="Level Logo"
        />
      </a>

      <button
        className={[`p-4`, `grow`, `xl:hidden`, `text-right`].join(` `)}
        onClick={() => setShowNav(() => true)}
        aria-label="open mobile navigation panel"
      >
        <FontAwesomeIcon
          className="text-3xl"
          icon={faBars}
          color="white"
          fontWeight="bold"
          aria-label="open mobile navigation panel"
        />
      </button>

      <div
        // navbar backdrop
        className={[
          `fixed`,
          `top-0`,
          `right-0`,
          `bg-[#080808c4]`,
          `w-full`,
          `h-screen`,
          `pl-10`,
          showNav ? `opacity-100` : `opacity-0`,
          showNav ? `visible` : `invisible`,
          `transition-all`,
          // viewport: md
          `xl:pl-0`,
          `xl:bg-transparent`,
          `xl:relative`,
          `xl:w-auto`,
          `xl:h-auto`,
          `xl:translate-x-0`,
          `xl:m-auto`, // center the nav
        ].join(` `)}
        style={{ zIndex: 500 }}
      >
        <ul
          style={{ marginRight: `${logoWidth}px` }}
          className={[
            `p-10`,
            `max-w-md`,
            `bg-level-purple`,
            `ml-auto`,
            `h-full`,
            `rounded-tl-[1.4rem]`,
            `rounded-bl-[1.4rem]`,
            `flex`,
            `flex-col`,
            `gap-6`,
            showNav ? `translate-x-0` : `translate-x-full`,
            `transition-transform`,
            // medium viewport and above.
            `xl:p-4`,
            `xl:flex-row`,
            `xl:gap-10`,
            `xl:max-w-[none]`,
            `xl:bg-transparent`,
            `xl:rounded-none`,
            `xl:rounded-none`,
          ].join(` `)}
        >
          <li
            className={[`flex`, `items-end`, `justify-end`, `xl:hidden`].join(
              ` `,
            )}
          >
            <button onClick={() => setShowNav(false)}>
              <FontAwesomeIcon
                className="text-3xl"
                color="#7356e8"
                icon={faXmark}
                fontWeight="bold"
              />
            </button>
          </li>

          {navItems.map((nav) => (
            <li
              key={nav.url}
              className={[
                `flex`,
                `gap-10`,
                `gap-x-4`,
                `items-center`,
                pathname === nav.url ? `font-bold` : ``,
                // medium viewport
                `xl:gap-0`,
                `xl:gap-x-0`,
                `xl:flex-col`,
                nav.showInMobileOnly ? `xl:hidden` : ``,
              ].join(` `)}
            >
              <span
                className={[
                  `md:hidden`,
                  `block`,
                  `min-w-[0.5rem]`,
                  `min-h-[0.5rem]`,
                  `rounded-full`,
                  pathname === nav.url ? `bg-[#7356e8]` : ``,
                ].join(` `)}
              />

              <a
                href={nav.url}
                className={[
                  `whitespace-nowrap`,
                  nav.type === `CAREER`
                    ? `inline-block ml-[-0.5em] px-[0.5em] pt-[0.3em] pb-[0.2em] font-bold bg-yellow-300 rounded-md mb-[0.4em]`
                    : ``,
                ].join(`,`)}
              >
                {nav.name}
              </a>

              {pathname === nav.url && (
                <span
                  className={[
                    `block`,
                    `h-1`,
                    `w-full`,
                    `bg-[#f1eefd]`,
                    `rounded`,
                    `xl:w-1/2`,
                    `xl:bg-[#7356e8]`,
                  ].join(` `)}
                />
              )}
            </li>
          ))}

          <FloatingNav />
        </ul>
      </div>
    </div>
  );
}
